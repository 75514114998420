export const projects = {
    engineering: [
        {
            title: "AI Artistry Website",
            description: "A static web app hosted on S3 for an AI consulting business. Leverages Qwik's resumability for optimized performance and Tailwind CSS for streamlined styling. Features dark mode autodeployed through AWS CodePipeline.",
            technologies: ["Qwik", "Tailwind", "Terraform", "AWS CodePipeline"],
            link: "aiartistry.io",
            thumbnail: null
        },
        {
            title: "Personal Website",
            description: "This website uses React.js, featuring React Router for navigation and React Grid Layout for dynamic layouts. Demonstrates scalable, interactive application development with modern front-end technologies.",
            technologies: ["ReactJS", "React-grid-layout", "Heroku"],
            thumbnail: null
        },
        {
            title: "Solutions Architecture for B2C Application",
            description: "Designed recommendation engine using microservice architecture with containers and Elasticsearch. Delivered MVP with streamlined front-end product details and focused back-end algorithm implementation.",
            technologies: ["Elasticsearch"],
            link: null,
            thumbnail: null
        },
        {
            title: "Meetup List Builder",
            description: "Built a command-line tool in PHP that searches Meetup groups and events by zip code using Meetup's public API. Implemented with PHPUnit testing, Composer dependency management, and robust error handling for invalid requests.",
            technologies: ["PHP", "PHPUnit", "Composer", "APIs"],
            link: "https://github.com/dnestoff/MeetupListBuilder",
            thumbnail: null
        }
    ],
    product: [
        {
            title: "Newmark Client Portal",
            description: "Led development of Newmark's first external client portal, a greenfield serverless application that cut document collection cycle time by 50%. Owned roadmap, collaborated with UX agency, and architected Azure-based system.",
            technologies: ["Azure", "Serverless", "CI/CD", "ReactJS"],
            link: "my.nmrk.com",
            thumbnail: null
        },
        {
            title: "AI Artistry Newsletter",
            description: "Founded and created a weekly newsletter helping individuals upskill on AI, teaching prompting techniques and providing in-depth articles for marketers, entrepreneurs, and authors.",
            technologies: ["AI", "Substack", "ChatGPT", "Claude"],
            link: "newsletter.aiartistry.io",
            thumbnail: null
        }
    ],
    data: [
        {
            title: "Client Reporting Automation",
            description: "Designed and built automated client reporting for Simplifya's enterprise SaaS app. Built data pipeline integrating SQL, shell scripting, and ETL processes to collect and normalize SQL and NoSQL databases. Dynamic dashboards created with Google Data Studio.",
            technologies: ["SQL", "Shell Scripting", "ETL", "Google Data Studio"],
            link: null,
            thumbnail: null
        },
        {
            title: "Fantasy Football Draft Tool",
            description: "Developed a Google Sheets-based draft tool evolving over five NFL seasons. Created automated data cleaning, real-time player comparisons, and trade analysis features, significantly reducing prep time while improving draft decisions.",
            technologies: ["Google Sheets", "Data Analysis", "Process Automation", "VBA"],
            link: "https://docs.google.com/spreadsheets/d/1377BeBngmbnxcouzUFOnGtHuGXtah2opAczyttXf1tM/edit?usp=drive_link",
            thumbnail: null
        }
    ],
};