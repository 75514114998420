import React from 'react';
import LogoItem from '../reusable/LogoItem.js';
import linkedin_logo from '../../logos/LI-In-Bug.png';
import pdf_placeholder from '../../icons/icons8-pdf-100.png';
import { LINKEDIN_URL, RESUME_ENG_URL, RESUME_PROD_URL, SUBSCRIBE_URL } from '../../config/urls.js';
import GridLayout from '../reusable/GridLayout';


function ContactPage() {
    // Define the layout for the grid
    const layout = [
        { i: 'left-section', x: 0, y: 0, w: 8, h: 12 },  // Left section takes up 2/3
        { i: 'right-section', x: 8, y: 0, w: 4, h: 12 }  // Right section takes up 1/3
    ];

    return (
        <GridLayout
            leftWidthRatio="2/3"
        >
            <div key="left-section" className="left-section">
                <h1>Contact</h1>
                <p>To discuss collaborations, job opportunities, or media inquiries, contact me via LinkedIn.</p>
                <LogoItem href={LINKEDIN_URL} src={linkedin_logo} alt="LinkedIn Logo" />
                <p>For personal and non-professional inquiries (or simply saying hello), don't hesitate to <a href="mailto:hello@davidnestoff.com">email me</a>.</p>
                <p>Just want to stay connected? <a href={SUBSCRIBE_URL} target="_blank" rel="noopener noreferrer">Join my mailing list</a> to receive updates on new music releases, short stories, and other exciting projects.</p>
            </div>
            <div key="right-section" className="right-section">
                <div className="resume-thumbnails">
                    <a href={RESUME_ENG_URL} target="_blank" rel="noopener noreferrer">
                        <div className="thumbnail">
                            <img src={pdf_placeholder} alt="Resume 1"/>
                            <p>Engineering Resume</p>
                        </div>
                    </a>
                    <a href={RESUME_PROD_URL} target="_blank" rel="noopener noreferrer">
                        <div className="thumbnail">
                            <img src={pdf_placeholder} alt="Resume 2"/>
                            <p>Product Resume</p>
                        </div>
                    </a>
                </div>
            </div>
        </GridLayout>
    );
}

export default ContactPage;