// SubscribePage.js
import React from 'react';
import MailerLiteForm from '../reusable/MailerLiteForm';

const SubscribePage = () => {
  return (
    <div className="Main-div">
        <div className="left-section">
            {/* Content for the left two-thirds */}
            <h1>Subscribe</h1>
            <p>Subscribe to receive updates from me about music, stories, and other interesting endeavors.</p>
        </div>
        <div className="right-section">
            {/* Content for the right one-third */}
            <MailerLiteForm />
        </div>
    </div>
  );
};

export default SubscribePage;
