import React from 'react';

const LogoItem = ({ href, src, alt }) => {
  return (
      <a className="logo" href={href} target="_blank" rel="noopener noreferrer">
        <img src={src} alt={alt} />
      </a>
  );
};

export default LogoItem;
