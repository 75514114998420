import '../Navigation.css';
import LogoItem from '../reusable/LogoItem.js';
import wp_logo from '../../logos/WordPress-logotype-simplified.png';
import yt_logo from '../../logos/yt_icon_mono_light.png';
import x_logo from '../../logos/x-logo-black.png';
import spotify_logo from '../../logos/Spotify_Primary_Logo_RGB_Black.png';
import medium_logo from '../../logos/Medium-Symbol-Black-RGB@4x.png';
import substack_logo from '../../logos/substack_logos.png';
import { SPOTIFY_URL, YOUTUBE_URL, STRANGERS_WINDOW_URL, X_URL, MEDIUM_URL, SUBSTACK_URL } from '../../config/urls.js';


function LeftNavigation() {
  return (
    <nav className="left-nav">
      <ul className="logo-list">
        <li className="logo-item">
          <LogoItem href={MEDIUM_URL} src={medium_logo} alt="Medium Logo" />
        </li>
        <li className="logo-item">
          <LogoItem href={STRANGERS_WINDOW_URL} src={wp_logo} alt="Wordpress Logo" />
        </li>
        <li className="logo-item">
          <LogoItem href={SUBSTACK_URL} src={substack_logo} alt="Substack Logo" />
        </li>
        <li className="logo-item">
          <LogoItem href={X_URL} src={x_logo} alt="X Logo" />
        </li>
        <li className="logo-item">
          <LogoItem href={YOUTUBE_URL} src={yt_logo} alt="YouTube Logo" />
        </li>
      </ul>
    </nav>
  );
}

export default LeftNavigation;
