import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Navigation.css';
import icon_hamburger from '../../icons/icon-hamburger-menu.svg';


function TopNavigation() {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    return (
        <nav className="top-nav">
            <ul className="nav-list">
                {location.pathname !== '/' && (
                    <li className="nav-item-left">
                        <Link to="/" className="back-link">
                            <svg className="back-icon" width="2em" height="2em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="12" fill="white"/>
                                <path d="M15 12H9M9 12L12 9M9 12L12 15" stroke="#606060" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </Link>
                    </li>
                )}
                <li className="nav-trigger">
                    <button onClick={() => setIsOpen(!isOpen)}>
                        <img className="hamburger-icon" src={icon_hamburger} alt="Menu"/>
                    </button>
                </li>
                <li className={`nav-menu ${isOpen ? 'isOpen' : ''}`}>
                    <ul>
                        <li className="nav-item">
                            <Link to="/contact" onClick={() => setIsOpen(!isOpen)}>Contact</Link>
                            <Link to="/portfolio" onClick={() => setIsOpen(!isOpen)}>Portfolio</Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );

}

export default TopNavigation;
