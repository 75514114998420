import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(Responsive);

const defaultLayout = [
  { i: 'left-section', x: 0, y: 0, w: 8, h: 12 },  // Left section takes up 2/3
  { i: 'right-section', x: 8, y: 0, w: 4, h: 12 }  // Right section takes up 1/3
];

const defaultBreakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
const defaultCols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };

const GridLayout = ({ 
  children, 
  layout = defaultLayout,
  breakpoints = defaultBreakpoints,
  cols = defaultCols,
  width = 1200
}) => {
  return (
    <div className="Main-div">
      <ResponsiveGridLayout
        className="layout"
        layout={layout}
        breakpoints={breakpoints}
        cols={cols}
        width={width}
        isDraggable={false}
        isResizable={false}
      >
        {children}
      </ResponsiveGridLayout>
    </div>
  );
};

export default GridLayout;
