import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(Responsive);

const getRatios = (leftWidthRatio) => {
  const [numerator, denominator] = leftWidthRatio.split('/').map(Number);
  const leftRatio = numerator / denominator;
  const rightRatio = 1 - leftRatio;
  return { leftRatio, rightRatio };
};

const getLayout = (leftWidthRatio, cols) => {
  const { leftRatio, rightRatio } = getRatios(leftWidthRatio);

  // Convert the ratios to grid units based on total columns
  const leftColumns = Math.round(cols.lg * leftRatio);
  const rightColumns = cols.lg - leftColumns;



  // Define the layout with specific grid positions
  return {
    layout: {
      lg: [
        { i: 'left', x: 0, y: 0, w: leftColumns, h: 1 },
        { i: 'right', x: leftColumns, y: 0, w: rightColumns, h: 1 }
      ],
      md: [
        { i: 'left', x: 0, y: 0, w: leftColumns, h: 1 },
        { i: 'right', x: leftColumns, y: 0, w: rightColumns, h: 1 }
      ],
      sm: [
        { i: 'left', x: 0, y: 0, w: cols.sm, h: 1 },
        { i: 'right', x: 0, y: 1, w: cols.sm, h: 1 }
      ],
      xs: [
        { i: 'left', x: 0, y: 0, w: cols.xs, h: 1 },
        { i: 'right', x: 0, y: 1, w: cols.xs, h: 1 }
      ]
    },
    ratios: { leftRatio, rightRatio }
  };
};

const defaultBreakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
const defaultCols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };

const GridLayout = ({ 
  children, 
  // layout = defaultLayout,
  leftWidthRatio = '2/3', // Can be '1/3', '1/2', '1/4', '3/4', etc.
  breakpoints = defaultBreakpoints,
  cols = defaultCols,
  width = 1200
}) => {
  const { layout, ratios } = getLayout(leftWidthRatio, cols);
  
  return (
    <div className="Main-div" style={{
      '--left-ratio': ratios.leftRatio,
      '--right-ratio': ratios.rightRatio
    }}>
      <ResponsiveGridLayout
        className="layout"
        layout={layout}
        breakpoints={breakpoints}
        cols={cols}
        width={width}
        isDraggable={false}
        isResizable={false}
      >
        {children}
      </ResponsiveGridLayout>
    </div>
  );
};

export default GridLayout;
