import React, { useState, useEffect } from 'react';
import { homeH2Array } from '../../config/content.js';
import beard_icon_dark from '../../icons/icon-beard-silhouette-dark-v2.svg';
import beard_icon_light from '../../icons/icon-beard-silhouette-light-v2.svg';
import GridLayout from '../reusable/GridLayout';

function HomePage() {
  const layout = [
    { i: 'left-section', x: 0, y: 0, w: 8, h: 12 },  // Left section takes up 2/3
    { i: 'right-section', x: 8, y: 0, w: 4, h: 12 }  // Right section takes up 1/3
  ];
  
  const [dynamicText, setDynamicText] = useState('Engineering Leader');
  const textArray = homeH2Array;

  useEffect(() => {
    let index = 0;
    let time_interval = 2500;
    const intervalId = setInterval(() => {
      index = (index + 1) % textArray.length;
      setDynamicText(textArray[index]);
    }, time_interval); // Change text every 3 seconds

    return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  return (
    <GridLayout
      layout={layout}
    >
      <div key="left-section" className="left-section">
          {/* Content for the left two-thirds */}
          <p>Welcome, I'm...</p>
          <h1>David Nestoff</h1>
          <h2>{dynamicText}</h2>
          <p>As a modern-day Renaissance man, I blend my passions for writing, music, and software engineering. With a unique combination of technical expertise, I'm passionate about emotionally intelligent engineering and what AI means for product teams. With over 100 original songs, short stories, and a novel in the works, I'm constantly exploring story-telling. Welcome to my journey, set against the inspiring backdrop of the Rocky Mountains.</p>
      </div>
      <div key="right-section" className="right-section">
        {/* Content for the right one-third */}
        <img className="icon icon-beard" src={beard_icon_dark} alt="Logo" />
      </div>
    </GridLayout>
  );
}

export default HomePage;
