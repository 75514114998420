// Bio Links
export const SPOTIFY_URL = "https://www.spotify.com";
export const YOUTUBE_URL = "https://www.youtube.com/@stoffinson";
export const STRANGERS_WINDOW_URL = "https://thestrangerswindow.com/";
export const X_URL = "https://x.com/davidnestoff";
export const LINKEDIN_URL = "https://www.linkedin.com/in/davidnestoff/";
export const MEDIUM_URL = "https://medium.com/@d.w.nestoff";
export const SUBSTACK_URL = "https://newsletter.aiartistry.io";

// Resumes
export const RESUME_ENG_URL = 'https://drive.google.com/file/d/1FsE09sN_VCQ1nFyDJ-DIGOdYZhnDXHid/view';
export const RESUME_PROD_URL = 'https://drive.google.com/file/d/1AFrbdx7oH32ijlbaBDFApY-0qkH9oI_K/view';

// Other
// TODO: Add URL from MailerLite
export const SUBSCRIBE_URL = 'https://subscribepage.io/cQpmGl';
