import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import HomePage from './pages/HomePage';
import Header from './Header';
import TopNavigation from './navigation/TopNavigation';
import LeftNavigation from './navigation/LeftNavigation';
import Writing from './pages/Writing';
import Music from './pages/Music';
import AI from './pages/AI';
import Contact from './pages/ContactPage';
import SubscribePage from './pages/SubscribePage';



function App() {
  return (
    <Router>
      <div className="App App-bg">
        <Header />
        <TopNavigation />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/writing" element={<Writing />} />
          <Route path="/music" element={<Music />} />
          <Route path="/ai" element={<AI />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/subscribe" element={<SubscribePage />} />
        </Routes>
        <LeftNavigation />
      </div>
    </Router>

  );
}

export default App;
