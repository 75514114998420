// MailerLiteForm.js
import React, { useEffect } from 'react';

const MailerLiteForm = () => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://assets.mailerlite.com/js/universal.js';
//     script.async = true;
//     script.onload = () => {
//       window.ml('account', '983680');
//     };

//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

  return (
    <div className="ml-embedded" data-form="5k3BNp"></div>
  );
};

export default MailerLiteForm;
