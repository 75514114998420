import React from 'react';
import GridLayout from '../reusable/GridLayout';
import { projects } from '../../config/projects';


function PortfolioPage() {
    const defaultSection = 'engineering'
    const [activeSection, setActiveSection] = React.useState(defaultSection); // Add state for active section
    const leftSectionWidth = 8; // 4 for 1/3 width, 8 for 2/3

    const layout = [
        { i: 'left-section', x: 0, y: 0, w: leftSectionWidth, h: 12 },
        { i: 'right-section', x: leftSectionWidth, y: 0, w: 12 - leftSectionWidth, h: 12 }
    ];

    const ProjectCard = ({ title, description, technologies, link, thumbnail }) => (
        <div className="project-card">
            <div className="project-header">
                {thumbnail && (
                    <div className="project-thumbnail">
                        <img src={thumbnail} alt={title} />
                    </div>
                )}
                <h3>{title}</h3>
            </div>
            <p>
                {description}
                { ' ' }
                {link && (
                    <a 
                        href={link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="project-link"
                    >
                        View →
                    </a>
                )}
            </p>
            <div className="tech-tags">
                {technologies.map((tech, index) => (
                    <span 
                        key={index} 
                        className="tech-tag"
                    >
                        {tech}
                    </span>
                ))}
            </div>
            
        </div>
    );

    return (
        <GridLayout
            leftWidthRatio="1/2"
        >
            <div key="left-section" className="left-section">
                <h1>Portfolio</h1>
                <p>
                    A collection of my work across product management, data, and software engineering.
                    Each project represents a unique challenge and innovative solution.
                </p>
                <nav className="section-nav">
                    <ul style={{ display: 'flex', listStyle: 'none', gap: '1rem', padding: 0 }}>
                        <li>
                            <a 
                                href="#engineering"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveSection('engineering');
                                }}
                                className={activeSection === 'engineering' ? 'active' : ''}
                            >
                                Engineering
                            </a>
                        </li>
                        <li>
                            <a 
                                href="#product"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveSection('product');
                                }}
                                className={activeSection === 'product' ? 'active' : ''}
                            >
                                Product
                            </a>
                        </li>
                        <li>
                            <a 
                                href="#data"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveSection('data');
                                }}
                                className={activeSection === 'data' ? 'active' : ''}
                            >
                                Data
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div key="right-section" className="right-section">
            <div 
                    key="product-section" 
                    className="section"
                    style={{ display: activeSection === 'product' ? 'block' : 'none' }}
                >
                    <h2>Product Management</h2>
                    <div className="project-grid">
                        {projects.product.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                        ))}
                    </div>
                </div>

                <div 
                    key="data-section" 
                    className="section"
                    style={{ display: activeSection === 'data' ? 'block' : 'none' }}
                >
                    <h2>Data</h2>
                    <div className="project-grid">
                        {projects.data.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                        ))}
                    </div>
                </div>

                <div 
                    key="engineering-section" 
                    className="section"
                    style={{ display: activeSection === 'engineering' ? 'block' : 'none' }}
                >
                    <h2>Software Engineering</h2>
                    <div className="project-grid">
                        {projects.engineering.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                        ))}
                    </div>
                </div>
            </div>
        </GridLayout>
    );
}

export default PortfolioPage; 