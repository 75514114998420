import React from 'react';

function Music() {
    return (
        <div className="Main-div">
            <div className="left-section">
                {/* Content for the left two-thirds */}
                <p>Welcome, I'm...</p>
                <h1>Dave Nestoff</h1>
                <h1>Music</h1>
                <p>Content for the Music page.</p>
            </div>
            <div className="right-section">
                {/* Content for the right one-third */}
            </div>   
        </div>
    );
}

export default Music;
